import { AUTOCOMPLETE } from './enum';
import { CLIENT_ENV } from '@/config/configuration';

export const isProduction = CLIENT_ENV().NEXT_PUBLIC_ENV === 'production';

export const MAPBOX_PLACES_BASE_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
export const SISALUTE_PROD_BASE_URL = 'https://sisereno.elty.it';
export const SISALUTE_BASE_URL = '/integrations/sisalute';
export const URL_SISALUTE_SURVEY = isProduction ? `${SISALUTE_PROD_BASE_URL}/questionario` : `${SISALUTE_BASE_URL}/questionario`;
export const SERVICE_NAME = 'SiSereno';
export const SERVICE_PHONE_NUMBER = '0513542031';
export const LOCATION_ACTUAL_POSITION_STRING = 'La tua posizione attuale';

export const PREFIXED_CALLCENTER_TELEPHONE_NUMBER = '+390297133028';
export const FORMATTED_CALLCENTER_TELEPHONE_NUMBER = '02 97133028';

export const DEFAULT_DOC_ALIAS = 'Professionista Sanitario';

export const DEFAULT_AUTOCOMPLETE_HEALTCARE_SERVICES = [
  { type: AUTOCOMPLETE.SPECIALITY, value: 'Visita Cardiologica' },
  { type: AUTOCOMPLETE.SPECIALITY, value: 'Visita Dermatologica' },
  { type: AUTOCOMPLETE.SPECIALITY, value: 'Visita Ginecologica' },
  { type: AUTOCOMPLETE.SPECIALITY, value: 'Visita Oculistica' },
  { type: AUTOCOMPLETE.SPECIALITY, value: 'Visita Urologica' },
  { type: AUTOCOMPLETE.SPECIALITY, value: 'Ecografia Addome Completo' },
  { type: AUTOCOMPLETE.SPECIALITY, value: 'Radiografia Torace (RX)' },
  { type: AUTOCOMPLETE.SPECIALITY, value: 'Esame del sangue' },
];

export const DEFAULT_AUTOCOMPLETE_LOCATIONS = [
  { type: AUTOCOMPLETE.LOCATION, value: LOCATION_ACTUAL_POSITION_STRING },
  { type: AUTOCOMPLETE.LOCATION, value: 'Milano' },
  { type: AUTOCOMPLETE.LOCATION, value: 'Torino' },
  { type: AUTOCOMPLETE.LOCATION, value: 'Roma' },
  { type: AUTOCOMPLETE.LOCATION, value: 'Bologna' },
  { type: AUTOCOMPLETE.LOCATION, value: 'Firenze' },
  { type: AUTOCOMPLETE.LOCATION, value: 'Palermo' },
];

import Modal from '@/app/_components/modal';
import { ReactNode } from 'react';

export const Sheet: React.FC<{ isOpen: boolean; onClose: () => void; children: ReactNode }> = ({ isOpen, onClose, children }) => {
  return (
    <Modal
      isModalOpen={isOpen}
      onClose={onClose}
      className={`mb-0 ml-auto mr-0 mt-0 h-screen max-h-full w-[628px] max-w-full animate-slideLeft backdrop:bg-black/50 open:animate-slideRight`}
    >
      {children}
    </Modal>
  );
};

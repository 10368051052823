import {
  AvailabilityDetailedSlotByIdDocument,
  BillingOptions,
  CheckCouponValidityQueryResult,
  PaymentType,
  SpecialityChannel,
} from '@/types-aggregatore';
import { ClinicAllowedPayments } from '@/store/createBookingInformationsSlice';
import { CheckoutFormFields, FormStep } from '@/store/createCheckoutSlice';
import client from './apolloClient';

export const formatPrice = ({ amount }: { amount?: null | number }) => {
  if (amount === null || amount === undefined) {
    console.error('amount is null or undefined');
    return '';
  }
  if (isNaN(amount)) {
    console.error('amount is NaN');
    return '';
  }
  return (amount / 100)
    .toLocaleString('it-IT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    })
    .replace(/\s/g, ' ')
    .trim();
};

export type CouponResponse = {
  amountSubtracted: number;
  newTotalAmount: number;
};

export const getClinicPaymentOptions = (availablePayments: PaymentType[]): ClinicAllowedPayments => {
  return {
    online: availablePayments?.includes(PaymentType.Online),
    onsite: availablePayments?.includes(PaymentType.Onsite),
    deferred: availablePayments?.includes(PaymentType.Deferred),
  };
};

export const calculateCouponDiscount = (
  coupon: CheckCouponValidityQueryResult['checkCouponValidity'],
  totalAmount: number,
): CouponResponse | null => {
  let amountToSubtract = 0;
  if (coupon?.fixed) {
    amountToSubtract = coupon.fixed;
  } else if (coupon?.percentage) {
    amountToSubtract = Math.floor((totalAmount / 10000) * coupon.percentage);
  }
  if (amountToSubtract > totalAmount) {
    return {
      amountSubtracted: amountToSubtract,
      newTotalAmount: 0,
    };
  } else {
    return {
      amountSubtracted: amountToSubtract,
      newTotalAmount: totalAmount - amountToSubtract,
    };
  }
};

/**
 * Generate a URL for the checkout page with the necessary parameters based on the input values.
 *
 * @param availabilitySlotId String representing the ID of the availability slot
 * @returns String representing the URL for the checkout page with the necessary parameters based on the input values.
 **/
type CheckoutUrlInput = {
  pathname: string;
  availabilitySlotId: null | string;
};
export const getCheckoutURL = (input: CheckoutUrlInput) => {
  return `${input.pathname}?slot=${input?.availabilitySlotId ?? ''}`;
};

export const fetchCheckoutData = async (
  availabilitySlotId: string,
  checkoutFormStep: FormStep,
  setBookingInformation: Function,
  updateCheckoutFormField: Function,
  setBookingInformationLoading: Function,
) => {
  if (availabilitySlotId && checkoutFormStep !== FormStep.BookingConfirmation) {
    setBookingInformation({
      slot: undefined,
      clinicAllowedPayments: undefined,
      reservationOrigin: undefined,
    });

    const { data, error } = await client.query({
      query: AvailabilityDetailedSlotByIdDocument,
      variables: { id: availabilitySlotId },
    });

    const slot = data?.availabilityDetailedSlotById;
    if (!slot || error) {
      throw new Error('Slot not found');
    }

    const clinicAllowedPayments = getClinicPaymentOptions(slot?.workgroup?.availablePayments ?? []);
    setBookingInformation({
      slot,
      clinicAllowedPayments: clinicAllowedPayments,
      reservationOrigin: undefined,
    });
    updateCheckoutFormField(
      'homeVisit',
      slot?.speciality?.specialityChannels?.includes(SpecialityChannel.Online) ? false : (slot.workgroup?.domiciliaryCare ?? false),
    );
    updateCheckoutFormField('isVideoConsultation', slot?.speciality?.specialityChannels?.includes(SpecialityChannel.Online) ?? false);
    updateCheckoutFormField('userPaymentSelected', clinicAllowedPayments?.online ? PaymentType.Online : PaymentType.Onsite);
    updateCheckoutFormField('totalAmount', slot.slotPrice ?? slot.speciality.basePrice);
    setBookingInformationLoading(false);
  }
};

export const getUserBillingInfo = (checkoutForm: CheckoutFormFields, billingOptions?: BillingOptions, isPaymentOnline?: boolean) => {
  if ((billingOptions === BillingOptions.OnlineOnly && !isPaymentOnline) || billingOptions === BillingOptions.NotRequired) {
    return null;
  }

  if (
    checkoutForm?.billingDistrict &&
    checkoutForm?.billingCity &&
    checkoutForm?.billingStreet &&
    checkoutForm?.billingStreetNumber &&
    checkoutForm?.billingCap
  ) {
    return {
      district: checkoutForm.billingDistrict,
      city: checkoutForm.billingCity,
      street: checkoutForm.billingStreet,
      streetNumber: checkoutForm.billingStreetNumber,
      cap: checkoutForm.billingCap,
    };
  } else if (
    checkoutForm.isDomiciliarySameAsBillingAddress &&
    checkoutForm?.homeVisitDistrict &&
    checkoutForm?.homeVisitCity &&
    checkoutForm?.homeVisitStreet &&
    checkoutForm?.homeVisitStreetNumber &&
    checkoutForm?.homeVisitCap
  ) {
    return {
      district: checkoutForm.homeVisitDistrict,
      city: checkoutForm.homeVisitCity,
      street: checkoutForm.homeVisitStreet,
      streetNumber: checkoutForm.homeVisitStreetNumber,
      cap: checkoutForm.homeVisitCap,
    };
  } else {
    return null;
  }
};

import { FC } from 'react';
import { AvailabilitySlotsForSpecialityNoAuthQueryResult } from '@/types-aggregatore';
import { formatPrice } from '@/utils/checkout';

type SlotAvailability = AvailabilitySlotsForSpecialityNoAuthQueryResult['availabilitySlotsForSpecialityNoAuth'][number];

export const RecapSection: FC<{ selectedSlot: SlotAvailability | null }> = ({ selectedSlot }) => {
  return (
    <div className={'grid grid-cols-3 rounded-md bg-primary-light-active px-4 py-3'}>
      <div className="flex flex-col gap-1 text-center">
        <div className="text-sm font-medium">Data</div>
        <div className="text-base font-semibold text-primary-active">
          {selectedSlot ? new Date(selectedSlot.start).toLocaleDateString('it-IT', { day: 'numeric', month: 'long' }) : ' - '}
        </div>
      </div>

      <div className="flex flex-col gap-1 text-center">
        <div className="text-sm font-medium">Orario</div>
        <div className="text-base font-semibold text-primary-active">
          {selectedSlot ? new Date(selectedSlot.start).toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' }) : ' - '}
        </div>
      </div>
      <div className="flex flex-col gap-1 text-center">
        <div className="text-sm font-medium">Prezzo</div>
        <div className="text-base font-semibold text-primary-active">
          {typeof selectedSlot?.price === 'number' ? `${formatPrice({ amount: selectedSlot.price })}` : ' - '}
        </div>
      </div>
    </div>
  );
};

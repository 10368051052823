import clsx from 'clsx/lite';

export const TimeButton: React.FC<{
  title: string;
  description?: string;
  onClick: () => void;
  isSelected: boolean;
  dataCy: string;
  dataTimestamp?: number;
}> = ({ title, description, onClick, isSelected, dataCy, dataTimestamp }) => {
  return (
    <button
      onClick={onClick}
      data-cy={dataCy}
      className={clsx(
        'flex min-h-10 cursor-pointer flex-col items-center justify-center rounded-lg py-2',
        isSelected
          ? 'border-brandBlue-400 bg-brandBlue-400 text-neutral-inverse'
          : 'border border-primary py-2 text-primary hover:border-primary-hover hover:bg-primary-light-hover hover:text-primary-hover',
      )}
      // Used in cypress
      {...(dataTimestamp ? { 'data-timestamp': dataTimestamp } : {})}
    >
      <span className="text-sm font-semibold">{title}</span>
      {description && <span className="text-sm font-normal">{description}</span>}
    </button>
  );
};

export const getStartOfDayTime = (time: number, daysToAdd = 0) => {
  const date = new Date(time ?? Date.now());
  date.setDate(date.getDate() + daysToAdd);
  date.setHours(0, 0, 0, 0);
  return date.getTime();
};

export const getDayTitle = (timestamp: number) => {
  const todayMs = getStartOfDayTime(Date.now());
  const tomorrowMs = getStartOfDayTime(Date.now(), 1);
  const date = new Date(timestamp);

  const isToday = timestamp === todayMs;
  const isTomorrow = timestamp === tomorrowMs;

  if (isToday) {
    return `Oggi ${date.toLocaleDateString('it', { day: 'numeric', month: 'long' })}`;
  }
  if (isTomorrow) {
    return `Domani ${date.toLocaleDateString('it', { day: 'numeric', month: 'long' })}`;
  }
  return date.toLocaleDateString('it', { weekday: 'long', day: 'numeric', month: 'long' });
};
